import React from "react";
import "./App.css";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import AppStoreBadge from "./assets/images/app-store-badge.svg";
import GooglePlayBadge from "./assets/images/google-play-badge.svg";
import Screenshot from "./assets/images/screenshot.png";
import TopChartBadge from "./assets/images/App Store Top 7.svg";
import Discord from "./assets/icons/discord.svg";
import Instagram from "./assets/icons/instagram.svg";
import TikTok from "./assets/icons/tiktok.svg";

const App = () => {
  return (
    <ParallaxProvider>
      <div className={"App"}>
        <div className={"m-6 text-center"}>
          <h1>Stranger Danger</h1>
          <h2>The endless social card game.</h2>

          <div className={"mx-16 mt-16 md:flex md:justify-center"}>
            <div>
              <a href="https://apps.apple.com/us/app/stranger-danger-game/id1571534215">
                <img
                  className={"mb-8 md:w-72 hd:w-96"}
                  src={AppStoreBadge}
                  alt="Download on the App Store"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.strangerdanger">
                <img
                  className={"md:w-72 hd:w-96"}
                  src={GooglePlayBadge}
                  alt="Get it on Google Play"
                />
              </a>
            </div>
          </div>

          <div className={"mt-4 lg:hidden flex justify-center"}>
            <img className={"w-40 h-40"} src={TopChartBadge} alt="" />
          </div>

          <div className={"md:flex md:justify-center"}>
            <img
              className={"md:w-[40em] hd:w-[60em]"}
              src={Screenshot}
              alt=""
            />
          </div>

          <div className={"lg:hidden mb-20"}>
            <h2>Connect with us</h2>
            <div className={"flex flex-row opacity-50 mt-4 justify-center"}>
              <Social src={Discord} link={"https://discord.gg/jsmf6wRpN4"} />
              <Social
                src={Instagram}
                link={"https://www.instagram.com/strangerdangergame/"}
              />
              <Social
                src={TikTok}
                link={"https://www.tiktok.com/@strangerdangergame"}
              />
            </div>
          </div>
        </div>

        <div className={"absolute left-0 bottom-0 m-20 invisible lg:visible"}>
          <img className={"w-96 h-96"} src={TopChartBadge} alt="" />
        </div>

        <div className={"absolute right-0 bottom-0 m-20 invisible lg:visible"}>
          <h2>Connect with us</h2>
          <div className={"flex flex-row opacity-50 mt-4"}>
            <Social src={Discord} link={"https://discord.gg/jsmf6wRpN4"} />
            <Social
              src={Instagram}
              link={"https://www.instagram.com/strangerdangergame/"}
            />
            <Social
              src={TikTok}
              link={"https://www.tiktok.com/@strangerdangergame"}
            />
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
};

export default App;

const Social = ({ link, src }: { link: string; src: string }) => {
  return (
    <a className={"m-2 flex justify-center"} href={link} target="_blank">
      <img className={"w-12 hover:scale-110"} src={src} alt="" />
    </a>
  );
};
